import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from '../App';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export const Features = (props) => {
  const indicatorStyles = {
    background: '#999',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log('handleChange', newValue);
  };

  function isMobole() {
    const moboleWidth = 768;
    return window.innerWidth <= moboleWidth;
  }

  const AppContext = useContext(Context);

  const [softData, setSoftData] = useState([]);//系统及软件
  const [aggData, setAggData] = useState([]);//骨料
  const [concData, setConcData] = useState([]);//混凝土
  const [assitsData, setAssistData] = useState([]);//助手

  function initData() {

    if (props.data) {
      setSoftData([props.data[0]]);
      setAggData([props.data[2], props.data[3], props.data[4], props.data[5], props.data[8]]);
      setConcData([props.data[1], props.data[6]]);
      setAssistData([props.data[0], props.data[7]]);

    }

  }

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      initData();
    }
  }, [props.data]);

  function elements(data) {
    var className = "";
    if (data && data.length === 1) {
      className = "col-xs-6 col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3"
    } else if (data) {
      className = "col-xs-6 col-md-6 col-lg-6 "
    }

    var rIndex = 0;
    
    var str='';
    
    let indexes=[];
    if (data) {
      for (var i = 0; i < data.length; i++) {
        rIndex = data[i].title.indexOf("R");

        indexes.push(rIndex);
      }
    }

    return (
      <div>

        {data.map((d, i) => {
          return (
            <div
              key={`${d.title}-${i}`}
              className={className}
              style={{
                marginBottom: '40px',
                display: 'inline-block',
                verticalAlign: 'bottom',
                //height: '450px'
              }}

            >

              <img
                src={d.icon}
                alt="img"
                className={"img-responsive"}
              ></img>
              <Link to={{ pathname: `/products/${d.index}` }} onClick={(e) => {
                AppContext.dispatch({
                  type: 'jump',
                  payload: `/products/${d.index}`
                })
              }}>
                {indexes[i] > 0 ? (
                  <h3>{d.title.substring(0, indexes[i] ).replace('R','')}<sup>&#174;</sup>{d.title.substring(indexes[i]+1, d.title.length ).replace('R','')}</h3>
                ) : (<h3>{d.title}</h3>)}

              </Link>

              <p style={{
                lineHeight: '30px',
              }}
              >{d.text}</p>
            </div>
          )
        })
        }
      </div>
    )
  }

  function scrollElements(data) {
    var className = "";
    if (data && data.length === 1) {
      className = "col-xs-12 col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3"
    } else if (data) {
      className = "col-xs-12 col-md-6 col-lg-6 col-md-offset-3"
    }
    var rIndex = 0;
    var str = "";
   
    if (data) {
      for (var i = 0; i < data.length; i++) {
        rIndex = data[i].title.indexOf("R");
        if (rIndex > 0) {
          break;
        }
      }
    }
   
    return (
      <Carousel
        autoPlay
        centerMode
        centerSlidePercentage={100}
        infiniteLoop
        showArrows
        swipeable
        stopOnHover
        showIndicators={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: '#000' }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {data.map((d,i)=>{
          return (
            <div
              key={`${d.title}-${i}`}
              className={className}
              style={{
                marginBottom: '40px',
                display: 'inline-block',
                verticalAlign: 'bottom',
                //height: '450px'
              }}

            >

              <img
                src={d.icon}
                alt="img"
                className={"img-responsive"}
              ></img>
              <Link to={{ pathname: `/products/${d.index}` }} onClick={(e) => {
                AppContext.dispatch({
                  type: 'jump',
                  payload: `/products/${d.index}`
                })
              }}>
                {rIndex > 0 ? (
                  <h3>{d.title.substring(0, rIndex )}<sup>&#174;</sup>{d.title.substring(rIndex+1 , d.title.length )}</h3>
                ) : (<h3>{d.title}</h3>)}

              </Link>

              <p style={{
                lineHeight: '30px',
              }}
              >{d.text}</p>
            </div>
          )
        })}
      </Carousel>
    )
  }

  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title"
          style={{
            marginBottom: '0px'
          }}>
          <h2>产品矩阵</h2>
        </div>

        <div className="col-md-10 col-md-offset-1 text-center"
          style={{
            marginBottom: '20px'
          }}>
          <Box sx={{
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
          }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={isMobole() ? 'scrollable' : 'standard'}
              scrollButtons
              allowScrollButtonsMobile
              centered
            >
              <Tab label="软件及数智控制系统"
                style={{
                  fontSize: '16px'
                }}
                value={0}
              />
              <Tab label="骨料检测物联终端"
                style={{
                  fontSize: '16px'
                }}
                value={1} />
              <Tab label="拌合物测物联终端"
                style={{
                  fontSize: '16px'
                }}
                value={2} />
              <Tab label="智能助手"
                style={{
                  fontSize: '16px'
                }}
                value={3} />
            </Tabs>
          </Box>
        </div>
        <div className="text-center row">
          {value === 0 ? (
            <>{isMobole()?( <>{scrollElements(softData)}</>):(<>{elements(softData)}</>)}</>
            
          ) : (null)}
          {value === 1 ? (
            <>{scrollElements(aggData)}</>
          ) : (null)}
          {value === 2 ? (
            <>{isMobole()?(<>{scrollElements(concData)}</>):(<>{elements(concData)}</>)}</>
          ) : (null)}
          {value === 3 ? (
            <>{isMobole()?(<>{scrollElements(assitsData)}</>):(<>{elements(assitsData)}</>) }</>
          ) : (null)}
        </div>

        {/*
        <div className="row" >
          {props.data
            ? props.data.map((d, i) => (
              <div
                key={`${d.title}-${i}`}
                className="col-xs-6 col-md-6 col-lg-4"
                style={{
                  marginBottom: '40px',
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                  height: '450px'
                }}

              >

                <img
                  src={d.icon}

                  alt="img"
                  className={"img-responsive"}
                ></img>
                <Link to={{ pathname: `/products/${i}` }} onClick={(e) => {
                  AppContext.dispatch({
                    type: 'jump',
                    payload: `/products/${i}`
                  })
                }}> <h3>{d.title}</h3></Link>

                <p style={{
                  lineHeight: '30px',
                  textAlign: 'left'
                }}
                >{d.text}</p>
              </div>
            ))
            : "Loading..."}
        </div>*/ }
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
