import React, { CSSProperties } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


export const Services = (props) => {
  const indicatorStyles = {
    background: '#999',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
  };
  function isMobole() {
    const moboleWidth = 768;
    return window.innerWidth <= moboleWidth;
  }

  const mobileClassname = "col-xs-12 col-md-6 col-lg-6 col-md-offset-3";
  const defaultClassname = "col-xs-6 col-md-6 col-lg-6";
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>技术与服务</h2>
        </div>
        {isMobole() ? (
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h4>{d.name}</h4>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
              : "loading"}
          </div>
        ) : (
          <Carousel
            autoPlay
            centerMode
            centerSlidePercentage={isMobole() ? 100 : 40}
            infiniteLoop={false}
            showStatus={false}
            showArrows={true}
            swipeable
            stopOnHover

            //thumbWidth={500}
            //showThumbs={true}
            showIndicators
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{ ...indicatorStyles, background: '#000' }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  />
                );
              }
              return (
                <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
          >

            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} style={{
                  width: '400px',
                  marginBottom: '40px',
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                }}
                  className={isMobole() ? mobileClassname : defaultClassname}>
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h4>{d.name}</h4>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
              : (null)}
          </Carousel>
        )}

        {/*<div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4">
                {" "}
                <i className={d.icon}></i>
                <div className="service-desc">
                  <h4>{d.name}</h4>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
            : "loading"}
            </div>*/}
      </div>
    </div>
  );
};
