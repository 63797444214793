import React from "react";

export const Header = (props) => {
  return (
    <header id="home">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8  intro-text">
                <h1>
                  {props.data ? props.data.title : "加载中"}<sup>®</sup>
                  <span></span>
                </h1>
                <p style={{
                  marginTop:'30px',
                  fontSize:'28px',
                  fontWeight:'bold'
                }}>{props.data ? props.data.secondTitle : "加载中"}</p>{''}
                <p
                style={{
                  marginTop:'30px',
                  fontSize:'24px',
                  fontWeight:'bold'
                }}>{props?.data?props.data.paragraph:''}</p>{''}
                {/*<a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
  </a>{" "}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
