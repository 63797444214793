
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


export const About = (props) => {


    const indicatorStyles = {
        background: '#999',
        width: 8,
        height: 8,
        display: 'inline-block',
        margin: '0 8px',
    };
    return (
        <div id="about">
            <div className="container">


                <div className="row">
                    <div className="col-12 text-center">
                        <h2>关于我们</h2>
                    </div>

                    
                    <div className="col-md-12 col-xs-12 text-center">
                        <Carousel
                            autoPlay
                            centerMode
                            centerSlidePercentage={100}
                            infiniteLoop={false}
                            showStatus={false}
                            showArrows={true}
                            swipeable
                            stopOnHover

                            //thumbWidth={500}
                            //showThumbs={true}
                            showIndicators
                            renderIndicator={(onClickHandler, isSelected, index, label) => {
                                if (isSelected) {
                                    return (
                                        <li
                                            style={{ ...indicatorStyles, background: '#000' }}
                                            aria-label={`Selected: ${label} ${index + 1}`}
                                            title={`Selected: ${label} ${index + 1}`}
                                        />
                                    );
                                }
                                return (
                                    <li
                                        style={indicatorStyles}
                                        onClick={onClickHandler}
                                        onKeyDown={onClickHandler}
                                        value={index}
                                        key={index}
                                        role="button"
                                        tabIndex={0}
                                        title={`${label} ${index + 1}`}
                                        aria-label={`${label} ${index + 1}`}
                                    />
                                );
                            }}
                        >

                            {(props.data&&props.data.imgs)
                                ? (props.data.imgs.map((d, i) => (
                                    <div key={`${i}`} >
                                        {" "}
                                        <img src={d} alt="img" className="img-responsive"></img>
                                    </div>
                                )))
                                : (null)}
                        </Carousel>
                    </div>
                    <div className="about-text" >
                        <p style={{textIndent:'2em',lineHeight:'32px'}}>{props.data&&props.data.paragraph?props.data.paragraph:"loading"}</p>
                    </div>
                    

                    {/*<div className="col-xs-12 col-md-6">
            <div className="slider-container">
              <Slider
                dots={true}
                infinite={false}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={true}
                adaptiveHeight={true}
                autoplaySpeed={2000}
                style={{
                  height: '450px'
                }}
              >
                <img src="img/team/01-lab.png" className="img-responsive" alt="实验室" />
                <img src="img/team/02-reward.png" className="img-responsive" alt="知识产权" />
              </Slider>
            </div>


          </div>
          <div className="col-xs-12 col-md-6">
            <div className="row">
              <div className="col-12 text-center">
                <h2>关于我们</h2>
              </div>
            </div>
            <div className="about-text">

              <p>{props.data ? props.data.paragraph : "loading..."}</p>


            </div>
              </div>*/}
                </div>
            </div>
        </div>
    );
};
