import React from "react"
export const Footer = (props) => {
    return (
        <div id="footer">
            <div className="container text-center">
                <p>
                    &copy; 2024 陶格科技工程（烟台）有限公司. ICP备案号：
                    <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">
                        鲁ICP备19064021号-5
                    </a>
                </p>
            </div>
        </div>
    )
}