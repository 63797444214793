import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from '../App';
export const Navigation = (props) => {
  const AppContext = useContext(Context);
 

  

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">切换</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <img
            src="../../img/header/logo.png"
            alt='logo'
            width={'200px'}
          >

          </img>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              {AppContext && AppContext.state && AppContext.state.url === "" ? (<a href="#home" className="page-scroll">
                首页
              </a>) : (

                <a className="page-scroll" href="/#home">首页</a>
              )}
             
            </li>
            <li>
              {AppContext && AppContext.state && AppContext.state.url === "" ? (<a href="#products" className="page-scroll">
                产品矩阵
              </a>) : (

                <a href="#products" className="page-scroll" onClick={(e)=>{
                  window.location.replace("/#products");
                  return false;
                }}>
                  产品矩阵
                </a>
              )}

            </li>
            <li>
              {AppContext && AppContext.state && AppContext.state.url === "" ? (<a href="#services" className="page-scroll">
                技术与服务
              </a>) : (

                <a href="/#services" className="page-scroll">
                  技术与服务
                </a>
              )}


            </li>
            <li>
              {AppContext && AppContext.state && AppContext.state.url === "" ? (<a href="#developments" className="page-scroll">
                咨询与开发
              </a>) : (

                <a href="/#developments" className="page-scroll">
                  咨询与开发
                </a>
              )}

            </li>
            {/*<li>
              {AppContext && AppContext.state && AppContext.state.url === "" ? (<a href="#specialtopic" className="page-scroll">
                专题专栏
              </a>) : (

                <a href="/#specialtopic" className="page-scroll">
                  专题专栏
                </a>
              )}

              </li>*/}
            <li>
              {AppContext && AppContext.state && AppContext.state.url === "" ? (<a href="#about" className="page-scroll">
                联系我们
              </a>) : (
                <a href="/#about" className="page-scroll">
                  联系我们
                </a>
              )}

            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
};
