
import { Tab, Tabs } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React, { useState } from "react";

export const Developments = (props) => {

  const [value, setValue] = useState(0);

  function element(data) {
    var clasName="col-xs-12 ";

    

    if(data&&data.items&&data.items.length){
      if(data.items.length<5){
        console.log('tiaojian 1')
        clasName=clasName+`col-md-${12/data.items.length} co-lg-${12/data.items.length}`;
      }else{
        console.log('tiaojian 2',data.items.length)
        clasName="col-xs-12 col-md-4 col-lg-4 ";
      }
    }else{
      console.log('tiaojian 3')
    }
    
    
    if(data){
      return (
        <>
          <div className="col-md-12">
            {" "}
  
  
            <div className="service-desc col-md-12" >
  
              <div className="row">
                {data.items?.map((i, ix) => {
                  return (
                    <div className={clasName} style={{
                      padding: 10
                    }}>
                      <Card sx={{}} key={ix}>
                        <CardMedia
                          sx={{ height: 240 }}
                          image={i.img}
                          title="green iguana"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {i.title}
                          </Typography>
  
                        </CardContent>
  
                      </Card>
                    </div>
  
                  )
                })}
              </div>
  
            </div>
          </div>
        </>
      )
    }else{
      return null;
    }
    
  }

  return (
    <div id="developments" className="text-center">
      <div className="container">
        <div className="section-title" style={{ marginBottom: 0 }}>
          <h2>咨询与开发</h2>
        </div>
        <div className="row">
          <Tabs value={value}
            centered

            onChange={(e, value) => {
              setValue(value);
            }}
            style={{
              marginBottom: '20px'
            }}>
            <Tab label='咨询' value={0}
              style={{
                fontSize: '16px'
              }}></Tab>
            <Tab label='研发' value={1}
              style={{
                fontSize: '16px'
              }}></Tab>
          </Tabs>
          {props.data&&props.data.length&&props.data.length>value?(<>{element(props.data[value])}</>):(null)}
          {/*props.data
            ? props.data.map((d, i) => (
              <>{i % 2 === 0 ?
                (
                  <div key={`${d.name}-${i}`} className="col-md-12">
                    {" "}
                    {<div className="col-xs-12 col-md-4">
                      <img className={"img-responsive"} src={d.img} height={'40%'} 
                      ></img>
                </div>}

                    <div className="service-desc col-md-12" >
                      {<h3>{d.title}</h3>
                      <p style={{

                        whiteSpace: 'pre-line',
                        textAlign: 'left'
                      }}>{d.des}</p>}
                      <div className="row">
                        {d.items?.map((i, ix) => {
                          return (
                            <div className="col-md-6 col-sm-12" style={{
                              padding: 10
                            }}>
                              <Card sx={{}} key={ix}>
                                <CardMedia
                                  sx={{ height: 360 }}
                                  image={i.img}
                                  title="green iguana"
                                />
                                <CardContent>
                                  <Typography gutterBottom variant="h5" component="div">
                                    {i.title}
                                  </Typography>

                                </CardContent>

                              </Card>
                            </div>

                          )
                        })}
                      </div>

                    </div>
                  </div>
                ) : (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-12"
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    {" "}
                    {<div className="service-desc cols-xs-12 col-md-6">
                      <h3>{d.title}</h3>
                      <p style={{
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                      }}>{d.des}</p>

                    </div>}
                    {/*<div className="col-xs-12 col-md-6">
                      <img className={"img-responsive"} src={d.img}
                        height={'40%'}

                      ></img>
                    </div>}
                    {d.items?.map((i, ix) => {
                      return (
                        <div className="col-md-4 col-sm-12" style={{
                          padding: 10
                        }}>
                          <Card sx={{}} key={ix}>
                            <CardMedia
                              sx={{ height: 360 }}
                              image={i.img}
                              title="green iguana"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h5" component="div">
                                {i.title}
                              </Typography>

                            </CardContent>

                          </Card>
                        </div>

                      )
                    })}

                  </div>
                )}</>

            ))
                  : "loading"}*/}
        </div>
      </div>
    </div>
  );
};
