import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import JsonData from "../../data/data.json";
import { Context } from "../../App";
import "../../App.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
export const ConWin = (props) => {

    const indicatorStyles = {
        background: '#999',
        width: 8,
        height: 8,
        display: 'inline-block',
        margin: '0 8px',
    };
    const AppContext = useContext(Context);

    const [data, setData] = useState({})
    const params = useParams();
    function getData() {
        let index = params.index;
        
        var fix=JsonData.Products.findIndex(p=>p.index===index);
        if(fix>=0){
            if (JsonData && JsonData.Products && JsonData.Products.length > Number(fix)) {
                setData(JsonData.Products[Number(fix)]);
            }
        }

        
    }

    useEffect(() => {
        
        getData();
    }, [])

    return (
        <div id="products" className="text-center">
            <div className="container">
                <div className="row" >
                    <div className="col-md-10 col-md-offset-1 section-title">
                       
                        {data&&data.title&&data.title.indexOf("R")>0?(<h2>{data.title.substring(0,data.title.indexOf("R"))}<sup>&#174;</sup>{data.title.indexOf("R")+1==data.title.length?"": data.title.substring(data.title.indexOf("R")+1,data.title.length-1)}</h2>):(<h2>{data ? data.title : 'loading'}</h2>)}
                        {data && data.secondTitle ? (<h3>{data ? data.secondTitle : ""}</h3>) : (null)}
                    </div>
                    
                    <div className="col-md-8  col-md-offset-2 col-xs-12 text-center">
                        {data?.imgs ? (
                            <>
                                {data?.imgs.length > 1 ? (
                                    <Carousel
                                        autoPlay
                                        centerMode
                                        centerSlidePercentage={100}
                                        infiniteLoop={false}
                                        showStatus={false}
                                        showArrows={true}
                                        swipeable
                                        stopOnHover
                                        dynamicHeight
                                        //thumbWidth={500}
                                        //showThumbs={true}
                                        showIndicators
                                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                                            if (isSelected) {
                                                return (
                                                    <li
                                                        style={{ ...indicatorStyles, background: '#000' }}
                                                        aria-label={`Selected: ${label} ${index + 1}`}
                                                        title={`Selected: ${label} ${index + 1}`}
                                                    />
                                                );
                                            }
                                            return (
                                                <li
                                                    style={indicatorStyles}
                                                    onClick={onClickHandler}
                                                    onKeyDown={onClickHandler}
                                                    value={index}
                                                    key={index}
                                                    role="button"
                                                    tabIndex={0}
                                                    title={`${label} ${index + 1}`}
                                                    aria-label={`${label} ${index + 1}`}
                                                />
                                            );
                                        }}
                                    >

                                        {data?.imgs?.map((i, ix) => {
                                            return (
                                                <div className="col-12 text-center" key={`${i}-${ix}`}>
                                                    <img className="img-responsive" width={'100%'} src={i} ></img>
                                                </div>
                                            )
                                        })}
                                    </Carousel>

                                ) : (
                                    <>
                                        {data.imgs.length === 1 ? (
                                            <div className="col-12 text-center">
                                                <img className="img-responsive" width={'100%'} src={data.imgs[0]} ></img>
                                            </div>
                                        ) : (null)}
                                    </>
                                )}
                            </>
                        ) : (null)}


                    </div>
                    <div className="col-md-12 col-xs-12">
                        {data?.paragraphs?.map((i, ix) => {
                            return (
                                <div key={ix}>
                                    <h3 style={{ textAlign: 'left' }}>{i.title}</h3>
                                    <p style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'left',
                                        lineHeight: '30px',

                                    }}>
                                        {i.content}
                                    </p>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>


        </div>
    )
}