import React, { useState, useEffect, useReducer,createContext  } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Developments } from "./components/developments";
import { SpecialTopic } from "./components/specialTopic";
import { Footer } from "./components/footer";
import { BrowserRouter, Route, Router, RouterProvider, Routes, createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import { ConWin } from "./components/products/conwin";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const BasicRouter = () => {
  <BrowserRouter>
    <Routes>
      <Route element={<Home />} path="/#"></Route>
      <Route element={<ConWin />} path="/products/:index"></Route>
    </Routes>
  </BrowserRouter>
}

const MyRoutes = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  }, {
    path: '/products/:index',
    element: <ConWin />
  }
])
export function reducer(state, action) {
  if (action.type === "jump") {
    return {
      url: action.payload
    }
  } else if (action.type === "back") {
    return {
      url: ''
    }
  }
  throw Error('Unknown action.');
}

export const Context = createContext(null)
const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [state, dispatch] = useReducer(reducer, { url: "" });
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (



    <div>

      <Context.Provider value={{state, dispatch: dispatch}}>
        <Navigation/>
        <RouterProvider router={MyRoutes}></RouterProvider>
      </Context.Provider>

      {/*
      <BasicRouter></BasicRouter>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      
      <Services data={landingPageData.Services} />
  <Developments data={landingPageData.Developments}></Developments>*/}
      {/*<Gallery data={landingPageData.Gallery} />*/}

      {/*<SpecialTopic data={landingPageData.SpecialTopic} />
      <About data={landingPageData.About} />*/}
      {/*<Team data={landingPageData.Team} />*/}
      {/*<Contact data={landingPageData.Contact} />*/}
      <Footer></Footer>
    </div>

  );
};

export default App;
